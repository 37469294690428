<template>
  <b-modal
      id="toggle-deposit-modal"
      :title="title"
      hide-footer
  >
    <ValidationObserver
        ref="creditTransferForm"
        v-slot="{ handleSubmit }"
    >
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-form-group
            label-cols-sm="12"
            label-cols-lg="4"
            label="จำนวน"
            label-for="amount"
        >
          <b-form-input
              id="amount"
              :value="txn.amount"
              readonly="readonly"
          ></b-form-input>
        </b-form-group>

        <ValidationProvider
            v-slot="{ errors }"
            :name="$t('fields.remark')"
            rules="required"
        >
          <b-form-group
              label-cols-sm="12"
              label-cols-lg="4"
              :label="`${$t('fields.remark')} *`"
              label-for="detail"
          >
            <b-form-textarea
                id="detail"
                v-model="remark"
                :state="errors[0] ? false : null"
            ></b-form-textarea>
          </b-form-group>
        </ValidationProvider>

        <div class="text-right">
          <b-button
              variant="light"
              class="mr-2"
              @click="$bvModal.hide('toggle-deposit-modal')"
          >
            {{ $t('buttons.cancel')}}
          </b-button>
          <b-overlay
              :show="loading"
              rounded="true"
              spinner-small
              class="d-inline-block"
          >
            <b-button
                type="submit"
                variant="primary"
                block
            >
              {{ $t('buttons.confirm')}}
            </b-button>
          </b-overlay>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>

import {mapActions} from 'vuex';

export default {
  name: 'ToggleDepositModal',
  props: {
    txn: {
      type: Object,
      default: () => {},
    },
    search: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      remark: ''
    }
  },
  computed: {
    title() {
      return (+this.txn.status === 0 || +this.txn.status === 1) ? 'ดึงเครดิตคืน': 'คืนเครดิต'
    }
  },
  methods: {
    ...mapActions(['toggleDepositStatus']),
    closeModal() {
      this.$bvModal.hide('toggle-deposit-modal')
    },
    async onSubmit() {
      this.loading = true
      await this.toggleDepositStatus({
        id: this.txn.id,
        remark: this.remark,
        search: this.search
      })
      this.closeModal()
      this.remark = '';
      this.loading = false
    },
  },
}
</script>
